import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Suspense } from "react";
import { Loader } from "@react-three/drei";
import Home from "./Home";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <>
      <div
        style={{
          position: "absolute",
          zIndex: 99,
          height: "100vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          pointerEvents: "none",
        }}
      >
        <Home />
      </div>
      <Suspense fallback={null}>
        <App />
      </Suspense>
      <Loader />
    </>
  </React.StrictMode>
);
