import React, { useState } from "react";
import { Modal, Input, Row, Checkbox, Button, Text } from "@nextui-org/react";
import { Mail } from "./mail";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";

const Home = () => {
  const [visible, setVisible] = useState(false);
  const [email, setEmail] = useState(null);
  const [newsletter, setNewsletter] = useState(true);
  const [snack, setSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");

  const closeHandler = () => {
    setEmail(null);
    setVisible(false);
  };

  const submitHandler = () => {
    axios
      .post("https://api.hyperauth.app/v1/joinWaitlist", {
        email: email,
        newsletter: newsletter,
      })
      .then(() => {
        setSnackMessage("Thanks for joining the waitlist!");
        setTimeout(() => {
          setVisible(false);
          setSnack(true);
        }, 700);
      })
      .catch((err) => {
        setSnackMessage(err.response.data.message);
        setTimeout(() => {
          setVisible(false);
          setSnack(true);
        }, 700);
      });
  };

  return (
    <>
      <div className="contentWrap">
        <div className="labelWrap">
          <h1 style={{ fontSize: "3rem" }}>HyperAuth</h1>
          <h3 style={{ fontSize: "1rem" }}>
            Multi Factor Authentication - Simplified.
          </h3>
        </div>
        <div className="labelWrap">
          <h1 style={{ fontSize: "3rem" }}>Coming Soon...</h1>
          <Button
            flat
            auto
            style={{ marginTop: "5px", width: "100%" }}
            color="error"
            icon={<Mail fill="currentColor" />}
            onClick={() => setVisible(true)}
          >
            Join the waitlist
          </Button>
        </div>
      </div>
      <Modal
        closeButton
        blur
        aria-labelledby="modal-title"
        open={visible}
        onClose={closeHandler}
      >
        <Modal.Header>
          <Text id="modal-title" size={18}>
            Join{" "}
            <Text b size={18}>
              HyperAuth
            </Text>{" "}
            Waitlist
          </Text>
        </Modal.Header>
        <Modal.Body>
          <Input
            clearable
            bordered
            fullWidth
            color="primary"
            size="lg"
            placeholder="Email"
            contentLeft={<Mail fill="currentColor" />}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Row justify="center">
            <Checkbox onChange={setNewsletter} checked={newsletter}>
              <Text size={14}>Subscribe to our newsletter</Text>
            </Checkbox>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button auto flat color="error" onClick={closeHandler}>
            Cancel
          </Button>
          <Button auto flat onClick={() => submitHandler()}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Snackbar
        open={snack}
        autoHideDuration={3000}
        onClose={() => setSnack(false)}
        message={snackMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </>
  );
};

export default Home;
