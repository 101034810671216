import { Canvas } from "@react-three/fiber";
import {
  useGLTF,
  PresentationControls,
  Stage,
  Center,
} from "@react-three/drei";
import { EffectComposer, DepthOfField } from "@react-three/postprocessing";

export default function App() {
  return (
    <Canvas
      shadows
      dpr={[1, 2]}
      camera={{ position: [0, 0, 4], fov: 50 }}
      style={{ height: "100vh" }}
    >
      <Stage preset="rembrandt" intensity={0.3} environment="city">
        <Center>
          <PresentationControls
            global
            config={{ mass: 2, tension: 500 }}
            snap={{ mass: 4, tension: 1500 }}
            rotation={[0.15, 0.3, 0]}
            polar={[-Math.PI / 3, Math.PI / 3]}
            azimuth={[-Math.PI / 1.4, Math.PI / 2]}
          >
            <Watch
              rotation={[-Math.PI / 2, 0, 0]}
              position={[0, 0.25, 0]}
              scale={0.0003}
            />
          </PresentationControls>
        </Center>
      </Stage>
      <EffectComposer>
        <DepthOfField focusDistance={4} focalLength={0} bokehScale={0} />
      </EffectComposer>
    </Canvas>
  );
}

function Watch() {
  const { nodes, materials } = useGLTF("/glbhyperauth.glb");
  return (
    <group position={[0, 0, 0]}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["Poly-Mat"].geometry}
        material={materials.Mat}
        scale={0.15}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["Poly-Mat5"].geometry}
        material={materials["Mat.5"]}
        scale={0.15}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["Poly-Mat2"].geometry}
        material={materials["Mat.2"]}
        scale={0.15}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["Poly-Mat4"].geometry}
        material={materials["Mat.4"]}
        scale={0.15}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["Poly-Mat3"].geometry}
        material={materials["Mat.3"]}
        scale={0.15}
      />
    </group>
  );
}
